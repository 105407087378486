<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Notícias</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

		<!-- noticias area start -->
      <section class="noticias__area pt-20 pb-200 grey-bg">
         <div class="container">
            <div class="row m-0">
					<noticia v-for="(noticia, index) in pesquisa.resultado" :key="index" :noticia="noticia" :index="index" :view="'Linha'" />
            </div>
            
				<div v-if="pesquisa.resultado.length == 0" class="mb-200">Nenhuma notícia encontrada</div>
            <div v-else-if="pesquisa.resultado.length % 50 == 0" class="text-center mt-40">
               <button type="button" class="t-y-btn t-y-btn-2" @click="getNoticias(null)">Ver mais</button>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'
import noticia from '@/components/noticias/Noticia.vue'

export default {
   name: 'Noticias',
   data : function () {
      return {
         pesquisa: {'pagina': 0, 'resultado': []}
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   components: {
      noticia
   },
   methods: {
      getNoticias : function (pagina) {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.pagina = pagina == null ? ++this.pesquisa.pagina : pagina

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getNoticias',
            params: {
					pagina: this.pesquisa.pagina
				}
			}).then(response => {
            if (this.pesquisa.pagina == 0) {
               this.pesquisa.resultado = []
            }

            response.data.forEach(p => {
               this.pesquisa.resultado.push(p)
            });
			}).catch(function (error) {
				if (error.response != undefined) {
               ref.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
				} else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   },
   mounted() {
      this.getNoticias(0)
   }
}

</script>